import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    to {
      transform: rotate(360deg);
    }
`;

export const LoaderWrapper = styled.div`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;

    &:before,
    &:after {
        content: '';
        position: absolute;
        border-radius: inherit;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #1dc077 0%, transparent 100%);
        animation: ${rotate} 0.5s infinite linear;
    }

    &:after {
        width: 85%;
        height: 85%;
        background-color: ${(props) => props.bgColor};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
