import axios from 'axios';

export async function logDiagnostic(data) {
    const enrichedData = {
        ...data,
        userAgent: navigator.userAgent,
        timestamp: new Date().toISOString(),
    };

    try {
        await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/teste', enrichedData, {
            headers: { 'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6' },
        });
    } catch (err) {
        console.error('Erro ao enviar log diagnóstico:', err);
    }
}
