import styled from 'styled-components';
import { IOSShare } from '../../../../components/icon';

const Content = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(23, 23, 23, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: 10vh 5% 0 5%;
    align-items: center;
`;

const LogoImage = styled.img`
    object-fit: contain;
    width: 87px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 24px;
`;

const Title = styled.h1`
    color: #f8f8f8;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 24px;
`;

const MainText = styled.p`
    color: #38aa76;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 64px;
`;

const Text = styled.p`
    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 32px;
`;

const TutorialStepCenter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 52px;
`;

const TutorialStep = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    margin-bottom: 40px;
`;

const TutorialStepNumber = styled.span`
    color: #25d987;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 1px solid #25d987;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 100px;
    position: relative;
`;

const TutorialStepText = styled.span`
    color: #f8f8f8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
`;

const TutorialStepSubText = styled.span`
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const Line = styled.div`
    position: absolute;
    bottom: -45px;
    width: 1px;
    height: 36px;
    background: #3c3c3c;
`;

export function IosInstallTutorial() {
    return (
        <Content>
            <LogoImage src='https://avel-hub-images.s3.us-east-2.amazonaws.com/doodles/current.png' alt='logo' />

            <Title>Adicione o App à sua tela de início</Title>

            <MainText>O App notifica você sobre novas reuniões e permite aceitá-las ou recusá-las diretamente no seu celular.</MainText>

            <Text>Siga os passos abaixo para instalar o app no seu iPhone: </Text>

            <TutorialStep>
                <TutorialStepNumber>
                    1<Line></Line>
                </TutorialStepNumber>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <TutorialStepText>
                        Toque no ícone de compartilhar <IOSShare size='17px' color='#38AA76' />
                    </TutorialStepText>
                    <TutorialStepSubText>Chrome: o ícone está no canto superior direito</TutorialStepSubText>
                    <TutorialStepSubText>Safari: o ícone está no canto inferior central</TutorialStepSubText>
                </div>
            </TutorialStep>

            <TutorialStepCenter>
                <TutorialStepNumber>
                    2<Line></Line>
                </TutorialStepNumber>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <TutorialStepText>Escolha "Adicionar à Tela de Início"</TutorialStepText>
                </div>
            </TutorialStepCenter>

            <TutorialStepCenter>
                <TutorialStepNumber>3</TutorialStepNumber>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <TutorialStepText>Toque em "Adicionar" para confirmar</TutorialStepText>
                </div>
            </TutorialStepCenter>
        </Content>
    );
}
