import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    width: 100%;
`;

const SearchInput = styled.input`
    background: #1e1e1e;
    font-size: 12.8px;
    color: #f0f0f0;
    padding: 11px 12px;
    border: 1px solid #ffffff26;
    border-radius: 6px;
    width: 100%;
    max-width: 285px;
`;

const ListContainer = styled.ul`
    background: #252525;
    margin: 4px 0 0 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ffffff26;
    border-radius: 6px;
    z-index: 10;
`;

const ListItem = styled.li`
    padding: 10px 12px;
    cursor: pointer;
    color: #f0f0f0;

    &:hover {
        background: #1e1e1e;
    }
`;

export function SearchableSelect({ options = [], placeholder = 'Pesquisar', onSelect, cleanAfterSelect, alreadySelected = [] }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    function handleClickOutside(event) {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const handleSelect = (option) => {
        cleanAfterSelect ? setSearchTerm('') : setSearchTerm(option?.name);
        setIsOpen(false);

        const alreadyOptions = [...(alreadySelected || []), option?.name];

        const filteredAlreadySelectedOptions = options?.filter((opt) => !alreadyOptions?.includes(opt?.name));

        setFilteredOptions(filteredAlreadySelectedOptions);

        if (onSelect) onSelect(option);
    };

    useEffect(() => {
        const filteredAlreadySelectedOptions = options?.filter((opt) => !alreadySelected?.includes(opt?.name));

        setFilteredOptions(filteredAlreadySelectedOptions?.filter((option) => option?.name?.toLowerCase().includes(searchTerm?.toLowerCase())));
    }, [searchTerm, options]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <Container ref={containerRef}>
            <SearchInput
                type='text'
                placeholder={placeholder}
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsOpen(true);
                }}
                onFocus={() => setIsOpen(true)}
            />

            {isOpen && filteredOptions.length > 0 && (
                <ListContainer>
                    {filteredOptions.map((option, index) => (
                        <ListItem key={index} onClick={() => handleSelect(option)}>
                            {option?.name}
                        </ListItem>
                    ))}
                </ListContainer>
            )}
        </Container>
    );
}
