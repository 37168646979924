import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { WebPage } from './views/web';
import { AppPage } from './views/app';
import { NotificationsTutorial } from './views/notificationsTutorial/general';
import { IosNotificationsTutorial } from './views/notificationsTutorial/ios';

const RedirectComponent = () => {
    const location = useLocation();
    const isApp = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

    const targetPath = isApp ? `/app${location.search}` : `/web${location.search}`;

    return <Navigate to={targetPath} />;
};

const RoutesController = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<RedirectComponent />} />

                <Route path='/web' element={<WebPage />} />

                <Route path='/app' element={<NotificationsTutorial />}>
                    <Route index element={<AppPage />} />
                </Route>

                <Route path='/notifications-ios' element={<IosNotificationsTutorial />} />
            </Routes>
        </Router>
    );
};

export default RoutesController;
