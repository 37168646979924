import styled, { keyframes } from 'styled-components';
import { Close, ContentCopy } from './icon';
import { SearchableSelect } from './SearchableSelect';
import { useEffect, useState } from 'react';
import axios from 'axios';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
    border: 2px solid #252525;
    border-top: 2px solid #118550;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: ${spin} 1s linear infinite;
    margin: 0 auto;
`;

const Overlay = styled.div`
    z-index: 9;
    background: #121212;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    opacity: 0.5;
`;

const ModalContainer = styled.div`
    z-index: 10;
    background: #252525;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
    max-width: 308px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.span`
    color: #f4f4f4;
    font-size: 20px;
    font-weight: bold;
`;

const CloseButton = styled.button`
    color: #f4f4f4;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0px;
    height: 24px;
`;

const Label = styled.span`
    color: #a2a1a6;
    font-size: 12px;
`;

const Input = styled.input`
    background: #1e1e1e;
    font-size: 12.8px;
    color: #f0f0f0;
    padding: 11px 12px;
    border: 1px solid #ffffff26;
    border-radius: 6px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

const CancelButton = styled.button`
    background: #353535;
    color: #dddddd;
    border: none;
    border-radius: 4px;
    width: 155px;
    height: 31px;
    font-size: 12.8px;
    cursor: pointer;
`;

const AcceptButton = styled.button`
    background: #118550;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    width: 155px;
    height: 31px;
    font-size: 12.8px;
    cursor: pointer;

    &:disabled {
        background: #1185501a;
        cursor: not-allowed;
    }
`;

const arrowIcon = encodeURIComponent(`
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 5L9 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `);

const Select = styled.select`
    background: #1e1e1e url('data:image/svg+xml,${arrowIcon}') no-repeat right 12px center;
    background-size: 12px 12px;
    color: #f0f0f0;
    font-size: 12.8px;
    padding: 11px 12px;
    padding-right: 32px; /* Para dar espaço à seta */
    border: 1px solid #ffffff26;
    border-radius: 6px;
    outline: none;
    cursor: pointer;

    /* Remove as setas padrão dos navegadores */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

const ParticipantsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
`;

const UserParticipant = styled.div`
    font-size: 12.8px;
    padding: 8px 12px;
    color: #ececec;
    border: 1px solid #ffffff26;
    border-radius: 6px;
    opacity: 0.5;
`;

const Participant = styled.div`
    font-size: 12.8px;
    padding: 8px 12px;
    color: #ececec;
    border: 1px solid #ffffff26;
    border-radius: 6px;

    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const Option = styled.option`
    cursor: pointer;
`;

const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const MessageText = styled.span`
    color: #a2a1a6;
    font-size: 12px;
`;

const Link = styled.div`
    font-size: 12.8px;
    padding: 11px 12px;
    color: #ececec;
    border: 1px solid #ffffff26;
    border-radius: 6px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const CopyButton = styled.a`
    background: #353535;
    color: #dddddd;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 31px;
    font-size: 12.8px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export function MeetModal({ user, cancel, setToastData, participants, clients }) {
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [successMeet, setSuccessMeet] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [meetLink, setMeetLink] = useState('');
    const [meetType, setMeetType] = useState('customer');
    const [meetingLoading, setMeetingLoading] = useState(false);

    const selectCustomer = (customer) => {
        setSelectedCustomer([customer]);
    };

    const removeParticipant = (participant) => {
        setSelectedParticipants(selectedParticipants.filter((p) => p !== participant));
    };

    const copyMeetLink = () => {
        navigator.clipboard.writeText(meetLink);

        setToastData({
            title: 'Link copiado',
            message: 'O link foi copiado para sua área de transferência',
            type: 'success',
        });
    };

    const createMeet = async () => {
        try {
            setMeetingLoading(true);

            const body = {
                userId: user?.userId,
                email: user?.email,
                sourceType: 1,
                sourceId: selectedCustomer?.cliente,
                activityDetail: 3,
                attendees: selectedParticipants,
            };
            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/create-meeting', body, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            if (response.data) {
                setSuccessMeet(true);
                setMeetLink(response.data);
            }
        } catch (error) {
            alert('Ocorreu um erro ao pegar a lista atual.', error);
        } finally {
            setMeetingLoading(false);
        }
    };

    return (
        <>
            <Overlay />
            <ModalContainer>
                <ModalHeader>
                    <TitleContainer>
                        <Title>Iniciar uma reunião</Title>
                        <MessageText>Inicie uma reunião de relacionamento instantânea</MessageText>
                    </TitleContainer>

                    <CloseButton disabled={meetingLoading} onClick={cancel}>
                        <Close size='19px' color='#EFEFEF' />
                    </CloseButton>
                </ModalHeader>

                {!successMeet ? (
                    <>
                        <InputsContainer>
                            {/* <InputContainer>
                                <Label>Tipo de reunião*</Label>

                                <Select defaultValue='' onChange={(event) => setMeetType(event.target.value)}>
                                    <Option value='' disabled>
                                        Selecione
                                    </Option>
                                    <Option value='customer'>Relacionamento</Option>
                                    <Option value='lead'>Prospecção</Option>
                                </Select>
                            </InputContainer> */}

                            {meetType === 'customer' && (
                                <InputContainer>
                                    <Label>Cliente*</Label>

                                    <SearchableSelect
                                        placeholder='Informe o nome do cliente'
                                        options={clients}
                                        onSelect={(value) => selectCustomer(value)}
                                        alreadySelected={selectedCustomer}
                                    />
                                </InputContainer>
                            )}

                            {meetType === 'lead' && (
                                <InputContainer>
                                    <Label>Deal ID</Label>

                                    <Input placeholder='Pesquisar Deal ID' />
                                </InputContainer>
                            )}

                            {meetType && (
                                <InputContainer>
                                    <Label>Participantes</Label>

                                    <SearchableSelect
                                        options={participants}
                                        onSelect={(value) => setSelectedParticipants((prevState) => [...prevState, value?.name])}
                                        cleanAfterSelect
                                        alreadySelected={selectedParticipants}
                                    />

                                    <ParticipantsContainer>
                                        <UserParticipant>{user?.name_display}</UserParticipant>
                                        {selectedParticipants?.map((participant) => {
                                            return (
                                                <Participant onClick={() => removeParticipant(participant)}>
                                                    {participant}
                                                    <Close size='15px' color='#EFEFEF' />
                                                </Participant>
                                            );
                                        })}
                                    </ParticipantsContainer>
                                </InputContainer>
                            )}
                        </InputsContainer>

                        <ButtonContainer>
                            <CancelButton disabled={meetingLoading} onClick={cancel}>
                                Cancelar
                            </CancelButton>
                            <AcceptButton onClick={createMeet} disabled={meetingLoading || !selectedCustomer}>
                                {meetingLoading ? <Spinner /> : 'Iniciar reunião'}
                            </AcceptButton>
                        </ButtonContainer>
                    </>
                ) : (
                    <>
                        <SuccessContainer>
                            <MessageText>Seu limite de leads não será alterado</MessageText>
                            <Link onClick={copyMeetLink}>
                                {meetLink}
                                <ContentCopy size='19px' color='#EFEFEF' />
                            </Link>
                        </SuccessContainer>

                        <CopyButton target='_blank' href={meetLink}>
                            Abrir agora
                        </CopyButton>
                    </>
                )}
            </ModalContainer>
        </>
    );
}
