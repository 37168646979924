import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { Toast } from '../../components/Toast';
import { DenyModal } from '../../components/denyModal';
import { CountdownTimer } from '../../components/countdownTimer';
import { ProfileModal } from '../../components/ProfileModal';
import { Meet, Pipedrive } from '../../components/icon';
import { urlBase64ToUint8Array } from '../../utils/base64ToUint8';
import { MeetModal } from '../../components/MeetModal';
import { LoaderWrapper } from '../../components/LoadingWrapper';
import { getCookie } from '../../utils/cookies';
import { logDiagnostic } from '../../utils/logDiagnostic';

const ContentLoadingWrapper = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(56, 183, 119, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: calc(50vh - 1.25rem) 0 0 0;
    align-items: center;
`;

const Header = styled.div`
    background: #252525;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
`;

const ProfileModalBackground = styled.div`
    z-index: 9;
    background: #121212;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0.5;
`;

const ProfileModalContent = styled.div`
    position: absolute;
    top: 45px;
    left: 20px;
    z-index: 10;
    width: 100%;
`;

const LogoImage = styled.img`
    object-fit: contain;
    width: 87px;
    height: 40px;
`;

const UserImage = styled.img`
    object-fit: cover;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    cursor: pointer;
`;

const Content = styled.div`
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const SectionTitle = styled.span`
    color: #c5c5c5;
    font-size: 10.24px;
    letter-spacing: 10%;
`;

const NewNotificationCard = styled.a`
    display: flex;
    flex-direction: column;
    background: #2c2c2c;
    border-radius: 8px;
    padding: 12px 16px;
    gap: 16px;

    text-decoration: none;
`;

const HistoricalNotificationCard = styled.div`
    display: flex;
    flex-direction: column;
    background: #1e1e1e;
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
`;

const NotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333333;
    padding-bottom: 12px;
`;

const NotificationHeaderLeft = styled.div`
    opacity: ${(props) => (props.isHidden ? '20%' : '100%')};
    display: flex;
    align-items: center;
    gap: 4px;
`;

const NotificationTitle = styled.span`
    color: #f4f4f4;
    font-size: 14px;
`;

const CountdownText = styled.span`
    color: #ed3658;
    font-size: 12px;
`;

const InfoRow = styled.div`
    opacity: ${(props) => (props.isHidden ? '20%' : '100%')};
    display: flex;
    justify-content: space-between;
`;

const InfoLabel = styled.span`
    color: #a2a1a6;
    font-size: 12px;
`;

const InfoValue = styled.span`
    color: #ffffff;
    font-size: 12px;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

const DenyButton = styled.button`
    background: #353535;
    color: #ed3658;
    border: none;
    border-radius: 4px;
    width: 155px;
    height: 31px;
    font-size: 12.8px;
`;

const AcceptButton = styled.button`
    background: #118550;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    width: 155px;
    height: 31px;
    font-size: 12.8px;
`;

const NoNotificationsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
`;

const NoNotificationsText = styled.span`
    color: #404040;
    font-size: 14px;
`;

const StatusBadge = styled.span`
    background: ${(props) => props.background};
    border: 1px solid ${(props) => props.border};
    border-radius: 6px;
    padding: 2px 6px;
    font-size: 12px;
    color: ${(props) => props.color};
`;

const GemImage = styled.img`
    width: 18px;
    height: 18px;
`;

const statusDict = {
    1: {
        text: 'Aceita',
        background: '#1D4E3B',
        border: '#246149',
        color: '#DDF9E4',
    },
    2: {
        text: 'Recusada',
        background: '#552726',
        border: '#673332',
        color: '#FFEBEB',
    },
    3: {
        text: 'Expirada',
        background: '#313337',
        border: '#45474A',
        color: '#E6E7EA',
    },
};

const notScheduledColors = {
    text: 'Sem reunião marcada',
    background: '#43BCF11A',
    border: '#43BCF129',
    color: '#43BCF1',
};

export function AppPage() {
    const [userId, setUserId] = useState(null);
    const [notificationsList, setNotificationsList] = useState([]);
    const [historicalNotificationsList, setHistoricalNotificationsList] = useState([]);
    const [notificationToDeny, setNotificationToDeny] = useState(null);
    const [toastData, setToastData] = useState(null);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [isMeetModalOpen, setIsMeetModalOpen] = useState(false);
    const [user, setUser] = useState();
    const [participants, setParticipants] = useState([]);
    const [clients, setClients] = useState([]);
    const [isAllDataLoading, setIsAllDataLoading] = useState(true);
    const [defaultImg, setDefaultImg] = useState();
    const [isClientsLoaded, setIsClientsLoaded] = useState(false);
    const [isNotificationsLoaded, setIsNotificationsLoaded] = useState(false);

    const handleCloseToast = () => {
        setToastData(null);
    };

    const updateNotificationStatus = useCallback(
        async (notificationId, notifyId, status) => {
            try {
                const notification = notificationsList.find((item) => item.notificationId === notificationId);

                setNotificationsList((prev) => prev.filter((item) => item.notificationId !== notificationId));

                setHistoricalNotificationsList((prev) => [{ ...notification, status }, ...prev]);

                const body = {
                    notifyId: notifyId,
                    notificationId: notificationId,
                    statusId: status,
                    scheduleId: notification?.schedule_meetings_id,
                    userId,
                };

                const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/notification-receiver', body, {
                    headers: {
                        'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                    },
                });

                if (response.status === 200) {
                    setToastData({
                        title: status === 1 ? 'Reunião aceita' : 'Reunião recusada',
                        message: status === 1 ? 'Você receberá um e-mail com mais informações' : 'Seu limite de leads não foi alterado',
                        type: status === 1 ? 'success' : 'error',
                    });
                }
            } catch (error) {
                alert('Ocorreu um erro ao atualizar o status.', error);
            }
        },
        [userId, notificationsList]
    );

    const getNotificationsList = useCallback(async () => {
        try {
            const body = { userId };
            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/list-notifications', body, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            setNotificationsList(response.data?.filter((notification) => new Date(notification.time_limit) > new Date()));
            setNotificationsList(response.data);

            await logDiagnostic({
                context: 'getNotificationsList',
            });
        } catch (error) {
            alert('Ocorreu um erro ao pegar a lista atual.', error);
        } finally {
            setIsNotificationsLoaded(true);
        }
    }, [userId]);

    const getHistoricalNotificationsList = useCallback(async () => {
        try {
            const body = { userId };
            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/list-historical-notifications', body, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            setHistoricalNotificationsList(response.data);
        } catch (error) {
            alert('Ocorreu um erro ao pegar o histórico.', error);
        }
    }, [userId]);

    const handleExpireNotification = useCallback((notificationId) => {
        setNotificationsList((prevList) => prevList.filter((notification) => notification.notificationId !== notificationId));
    }, []);

    const checkAndRenewSubscription = useCallback(async () => {
        if (!userId) return;

        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();

        if (subscription) {
            await subscription.unsubscribe();
        }

        const newSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        });

        const subJson = { ...newSubscription.toJSON(), userId };
        await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/login', subJson, {
            headers: {
                'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
            },
        });
    }, [userId]);

    async function fetchUserId() {
        const storedId = getCookie('id');
        const localStoredId = localStorage.getItem('id');

        const id = storedId || localStoredId;

        setUserId(id);
    }

    const getUserInfo = useCallback(async () => {
        if (!userId) return;

        try {
            const body = { userId };
            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/app-user-info', body, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            setUser(response.data);
        } catch (error) {
            alert('Ocorreu um erro ao buscar seu score', error);
        }
    }, [userId]);

    const getUserMeetData = async () => {
        try {
            const body = { userId: Number(user?.userId), sectorId: user?.sectorId };
            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/get-clients', body, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            setParticipants(response.data.names);
            setClients(response.data.clients);

            await logDiagnostic({
                context: 'getUserMeetData',
            });
        } finally {
            setIsClientsLoaded(true);
        }
    };

    useEffect(() => {
        fetchUserId();
    }, []);

    useEffect(() => {
        checkAndRenewSubscription();
        getUserInfo();
    }, [userId]);

    useEffect(() => {
        if (user) {
            getUserMeetData();
        }
    }, [user]);

    useEffect(() => {
        if (navigator.serviceWorker) {
            const listener = async (event) => {
                if (event.data && event.data.action === 'updateList') {
                    await getNotificationsList();
                    await getHistoricalNotificationsList();
                }
            };

            navigator.serviceWorker.addEventListener('message', listener);

            return () => {
                navigator.serviceWorker.removeEventListener('message', listener);
            };
        }
    }, [getNotificationsList]);

    useEffect(() => {
        if (userId) {
            getNotificationsList();
            getHistoricalNotificationsList();
        }
    }, [userId]);

    useEffect(() => {
        logDiagnostic({
            context: 'isClientsLoaded && isNotificationsLoaded',
            isClientsLoaded,
            isNotificationsLoaded,
            teste: isClientsLoaded && isNotificationsLoaded,
        });

        if (isClientsLoaded && isNotificationsLoaded) {
            setIsAllDataLoading(false);
        }
    }, [isClientsLoaded, isNotificationsLoaded]);

    if (isAllDataLoading) {
        return (
            <ContentLoadingWrapper>
                <LoaderWrapper bgColor='#171717' />
            </ContentLoadingWrapper>
        );
    }

    return (
        <>
            <Header>
                <UserImage
                    onClick={() => setIsUserModalOpen(true)}
                    src={defaultImg || `https://avel-hub-images.s3.us-east-2.amazonaws.com/users/${userId}/profile.jpg`}
                    onError={() => setDefaultImg('https://avel-hub-images.s3.us-east-2.amazonaws.com/default/user.png')}
                />
                <LogoImage src='https://avel-hub-images.s3.us-east-2.amazonaws.com/doodles/current.png' alt='logo' />
                <Meet
                    size='20px'
                    color='none'
                    style={{
                        stroke: '#ABABAB',
                        strokeWidth: '1.8',
                        cursor: 'pointer',
                    }}
                    onClick={() => setIsMeetModalOpen(true)}
                />

                {isUserModalOpen && userId && (
                    <ProfileModalContent>
                        <ProfileModal cancel={() => setIsUserModalOpen(false)} user={user} />
                    </ProfileModalContent>
                )}
            </Header>

            {isUserModalOpen && userId && <ProfileModalBackground />}

            <Content>
                <Section>
                    <SectionTitle>NOVAS</SectionTitle>
                    {notificationsList?.length > 0 ? (
                        notificationsList.map((notification) => (
                            <NewNotificationCard key={notification.notificationId}>
                                <NotificationHeader>
                                    <NotificationHeaderLeft>
                                        <GemImage
                                            src={`https://avel-hub-images.s3.us-east-2.amazonaws.com/score/gem_${notification.faixa}.svg`}
                                            alt='gem'
                                        />
                                        <NotificationTitle>Reunião {notification.faixa}</NotificationTitle>
                                    </NotificationHeaderLeft>
                                    <CountdownText>
                                        <CountdownTimer
                                            timeLimit={notification.time_limit}
                                            onExpire={() => handleExpireNotification(notification.notificationId)}
                                        />{' '}
                                        restantes
                                    </CountdownText>
                                </NotificationHeader>

                                <InfoRow>
                                    <InfoValue>
                                        {moment(notification.data).format('DD/MM/YY')} <span style={{ color: '#A2A1A6' }}>às</span>{' '}
                                        {moment(notification.data).format('HH:mm')}
                                    </InfoValue>
                                    <InfoLabel>{notification.local || '-'}</InfoLabel>
                                </InfoRow>

                                <ButtonGroup>
                                    <DenyButton onClick={() => setNotificationToDeny(notification)}>Recusar</DenyButton>
                                    <AcceptButton onClick={() => updateNotificationStatus(notification.notificationId, notification.notifyId, 1)}>
                                        Aceitar
                                    </AcceptButton>
                                </ButtonGroup>
                            </NewNotificationCard>
                        ))
                    ) : (
                        <NoNotificationsContainer>
                            <NoNotificationsText>Não há novas reuniões</NoNotificationsText>
                        </NoNotificationsContainer>
                    )}
                </Section>

                <Section>
                    <SectionTitle>
                        HISTÓRICO <span style={{ color: '#404040' }}>(ESTA SEMANA)</span>
                    </SectionTitle>
                    {historicalNotificationsList?.map((notification) => {
                        const isScheduled = !!notification.scheduled;
                        const notificationStatus = isScheduled ? statusDict[Number(notification.status || 1)] : notScheduledColors;

                        return (
                            <HistoricalNotificationCard key={notification.notificationId}>
                                <NotificationHeader>
                                    <NotificationHeaderLeft isHidden={Number(notification.status) !== 1}>
                                        <GemImage
                                            src={`https://avel-hub-images.s3.us-east-2.amazonaws.com/score/gem_${notification.faixa}.svg`}
                                            alt='gem'
                                        />
                                        <NotificationTitle>
                                            {isScheduled ? 'Reunião' : 'Lead'} {notification.faixa}
                                        </NotificationTitle>
                                    </NotificationHeaderLeft>
                                    <StatusBadge
                                        background={notificationStatus.background}
                                        border={notificationStatus.border}
                                        color={notificationStatus.color}
                                    >
                                        {notificationStatus.text}
                                    </StatusBadge>
                                </NotificationHeader>

                                <InfoRow isHidden={Number(notification.status) !== 1}>
                                    <InfoValue>
                                        {!isScheduled && <span style={{ color: '#A2A1A6' }}>Recebido em </span>}
                                        {moment(notification.data).format('DD/MM/YY')} <span style={{ color: '#A2A1A6' }}>às</span>{' '}
                                        {moment(notification.data).format('HH:mm')}
                                    </InfoValue>
                                    <InfoLabel>{notification.local || '-'}</InfoLabel>
                                </InfoRow>
                            </HistoricalNotificationCard>
                        );
                    })}
                </Section>
            </Content>

            {isMeetModalOpen && (
                <MeetModal
                    participants={participants}
                    clients={clients}
                    setToastData={setToastData}
                    user={user}
                    cancel={() => setIsMeetModalOpen(false)}
                />
            )}

            {notificationToDeny && (
                <DenyModal
                    cancel={() => setNotificationToDeny(null)}
                    deny={() => {
                        updateNotificationStatus(notificationToDeny.notificationId, notificationToDeny.notifyId, 2);
                        setNotificationToDeny(null);
                    }}
                />
            )}

            {toastData && <Toast type={toastData.type} title={toastData.title} message={toastData.message} onClose={handleCloseToast} />}
        </>
    );
}
