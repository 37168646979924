import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { urlBase64ToUint8Array } from '../../../utils/base64ToUint8';
import axios from 'axios';
import { LoaderWrapper } from '../../../components/LoadingWrapper';
import { getCookie } from '../../../utils/cookies';
import { ArrowForward } from '../../../components/icon';

const Content = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(56, 183, 119, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: 10vh 5% 0 5%;
    align-items: center;
`;

const LogoImage = styled.img`
    object-fit: contain;
    width: 87px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 24px;
`;

const Title = styled.h1`
    color: #f8f8f8;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 24px;
`;

const MainText = styled.p`
    color: #38aa76;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 64px;
`;

const Text = styled.p`
    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 32px;
`;

const TutorialStepCenter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 52px;
`;

const TutorialStepNumber = styled.span`
    color: #25d987;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 1px solid #25d987;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 100px;
    position: relative;
`;

const TutorialStepText = styled.span`
    color: #f8f8f8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const Line = styled.div`
    position: absolute;
    bottom: -45px;
    width: 1px;
    height: 36px;
    background: #3c3c3c;
`;

const AcceptButtonBorder = styled.div`
    border: 1px solid #2891614a;
    padding: 2px;
    border-radius: 10px;
`;

const AcceptButton = styled.button`
    background: #118550;
    color: #ffffff;
    border: 1px solid #289161;
    border-radius: 8px;
    width: 155px;
    height: 38px;
    font-size: 12.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

export function IosNotificationsTutorial() {
    const [userId, setUserId] = useState(null);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

    const navigate = useNavigate();

    async function subscribeToPush() {
        setIsSubscriptionLoading(true);

        if (!('serviceWorker' in navigator)) {
            alert('Service Workers não são suportados neste navegador.');
            setIsSubscriptionLoading(false);
            return;
        }

        try {
            const registration = await navigator.serviceWorker.ready;
            const permission = await Notification.requestPermission();

            if (permission !== 'granted') {
                alert('Você precisa permitir as notificações no seu celular.');
                setIsSubscriptionLoading(false);
                return;
            }

            const applicationServerKey = urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY);
            const existingSubscription = await registration.pushManager.getSubscription();

            if (existingSubscription) {
                await existingSubscription.unsubscribe();
            }

            if (!userId) {
                alert('Erro! Pedimos desculpas pelo inconveniente. Por favor, delete o aplicativo e instale-o novamente.');
                setIsSubscriptionLoading(false);
                return;
            }

            const newSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey,
            });

            const subJson = { ...newSubscription.toJSON(), userId };

            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/login', subJson, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            if (response.status === 200) {
                navigate('/app');
                setIsSubscriptionLoading(false);
            }
        } catch (error) {
            alert('Ocorreu um erro ao tentar se inscrever.');
            setIsSubscriptionLoading(false);
        }
    }

    async function fetchUserId() {
        const storedId = await getCookie('id');

        if (storedId) {
            localStorage.setItem('id', storedId);
        }

        const localStoredId = localStorage.getItem('id');

        const id = storedId || localStoredId;

        setUserId(id);
    }

    useEffect(() => {
        fetchUserId();
    }, []);

    return (
        <Content>
            <LogoImage src='https://avel-hub-images.s3.us-east-2.amazonaws.com/doodles/current.png' alt='logo' />

            <Title>As notificações são necessárias</Title>

            <MainText>O App perde sua função sem a permissão para notificações</MainText>

            <Text>Ative as notificações no seu iPhone: </Text>

            <TutorialStepCenter>
                <TutorialStepNumber>
                    1<Line></Line>
                </TutorialStepNumber>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <TutorialStepText>
                        Vá em <strong>Ajustes `&gt;` Notificações</strong>
                    </TutorialStepText>
                </div>
            </TutorialStepCenter>

            <TutorialStepCenter>
                <TutorialStepNumber>
                    2<Line></Line>
                </TutorialStepNumber>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <TutorialStepText>Escolha o App da Ável na lista</TutorialStepText>
                </div>
            </TutorialStepCenter>

            <TutorialStepCenter>
                <TutorialStepNumber>3</TutorialStepNumber>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <TutorialStepText>
                        Ative a opção <strong>“Permitir Notificações”</strong> e volte ao App
                    </TutorialStepText>
                </div>
            </TutorialStepCenter>

            {!isSubscriptionLoading ? (
                <AcceptButtonBorder>
                    <AcceptButton onClick={subscribeToPush}>
                        Já ativei <ArrowForward size='20px' color='#FFFFFF' />
                    </AcceptButton>
                </AcceptButtonBorder>
            ) : (
                <LoaderWrapper bgColor='#171717' />
            )}
        </Content>
    );
}
