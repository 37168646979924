import styled from 'styled-components';
import { Close } from './icon';

const Overlay = styled.div`
    z-index: 9;
    background: #121212;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0.5;
`;

const ModalContainer = styled.div`
    z-index: 10;
    background: #252525;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    max-width: 308px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.span`
    color: #f4f4f4;
    font-size: 14px;
    font-weight: bold;
`;

const CloseButton = styled.span`
    color: #f4f4f4;
`;

const MessageText = styled.span`
    color: #a2a1a6;
    font-size: 12px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

const CancelButton = styled.button`
    background: #353535;
    color: #dddddd;
    border: none;
    border-radius: 4px;
    width: 155px;
    height: 31px;
    font-size: 12.8px;
    cursor: pointer;
`;

const DenyButton = styled.button`
    background: #353535;
    color: #ed3658;
    border: none;
    border-radius: 4px;
    width: 155px;
    height: 31px;
    font-size: 12.8px;
    cursor: pointer;
`;

export function DenyModal({ cancel, deny }) {
    return (
        <>
            <Overlay />
            <ModalContainer>
                <ModalHeader>
                    <Title>Recusar reunião?</Title>
                    <CloseButton onClick={cancel}>
                        <Close size='19px' color='#EFEFEF' />
                    </CloseButton>
                </ModalHeader>

                <MessageText>Seu limite de leads não será alterado</MessageText>

                <ButtonContainer>
                    <CancelButton onClick={cancel}>Cancelar</CancelButton>
                    <DenyButton onClick={deny}>Recusar</DenyButton>
                </ButtonContainer>
            </ModalContainer>
        </>
    );
}
