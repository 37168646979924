import styled from 'styled-components';
import { urlBase64ToUint8Array } from '../../../utils/base64ToUint8';
import axios from 'axios';
import isIos from '../../../utils/isIos';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LoaderWrapper } from '../../../components/LoadingWrapper';
import { getCookie } from '../../../utils/cookies';
import { NotificationsActive } from '../../../components/icon';
import { EmailLoginPage } from '../../email-login';
import { logDiagnostic } from '../../../utils/logDiagnostic';

const ContentLoadingWrapper = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(56, 183, 119, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: calc(50vh - 1.25rem) 0 0 0;
    align-items: center;
`;

const Content = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(56, 183, 119, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: 10vh 5% 0 5%;
    align-items: center;
`;

const LogoImage = styled.img`
    object-fit: contain;
    width: 87px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 32px;
`;

const Title = styled.h1`
    color: #f8f8f8;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 12px;
`;

const MainText = styled.p`
    color: #38aa76;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 32px;
`;

const AcceptButtonBorder = styled.div`
    border: 1px solid #2891614a;
    padding: 2px;
    border-radius: 10px;
`;

const AcceptButton = styled.button`
    background: #118550;
    color: #ffffff;
    border: 1px solid #289161;
    border-radius: 8px;
    width: 155px;
    height: 38px;
    font-size: 12.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const AvelBell = styled.img`
    width: 314px;
    height: 295px;
`;

async function getRegistrationWithPushManager(retryCount = 10, delayMs = 300) {
    let registration = await navigator.serviceWorker.ready;
    let count = 0;

    while (!(registration.pushManager && typeof registration.pushManager.subscribe === 'function') && count < retryCount) {
        await new Promise((resolve) => setTimeout(resolve, delayMs));
        registration = await navigator.serviceWorker.ready;
        count++;
        console.log('Aguardando pushManager...', count);
    }

    return registration;
}

async function waitForSubscription(registration, retryCount = 10, delayMs = 500) {
    let count = 0;
    let subscription = await registration.pushManager.getSubscription();

    while (!subscription && count < retryCount) {
        await new Promise((resolve) => setTimeout(resolve, delayMs));
        subscription = await registration.pushManager.getSubscription();
        count++;
        console.log('Aguardando inscrição ativa...', count);
    }

    return subscription;
}

export function NotificationsTutorial() {
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notificationsEnabled, setNotificationsEnabled] = useState(Notification.permission === 'granted');
    const [existingSubscription, setExistingSubscription] = useState(null);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

    const navigate = useNavigate();

    async function fetchUserId() {
        const storedId = getCookie('id');
        const localStoredId = localStorage.getItem('id');

        logDiagnostic({ teste: 1, storedId, localStoredId });

        const id = storedId || localStoredId;

        logDiagnostic({ teste: 2, id });
        setUserId(id);
        return id;
    }

    async function subscribeToPush() {
        setIsSubscriptionLoading(true);

        if (!('serviceWorker' in navigator)) {
            alert('Service Workers não são suportados neste navegador.');
            setIsSubscriptionLoading(false);
            return;
        }

        try {
            const registration = await getRegistrationWithPushManager();
            const permission = await Notification.requestPermission();

            if (permission !== 'granted' && isIos()) {
                navigate('/notifications-ios');
                setIsSubscriptionLoading(false);
                return;
            }

            if (permission !== 'granted') {
                alert('Você precisa permitir as notificações no seu celular.');
                setIsSubscriptionLoading(false);
                return;
            }

            const applicationServerKey = urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY);

            if (existingSubscription) {
                await existingSubscription.unsubscribe();
            }

            logDiagnostic({ teste: 3, userId });

            if (!userId) {
                alert('Erro! Pedimos desculpas pelo inconveniente. Por favor, delete o aplicativo e instale-o novamente.');
                setIsSubscriptionLoading(false);
                return;
            }

            const newSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey,
            });

            const subJson = { ...newSubscription.toJSON(), userId };

            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/login', subJson, {
                headers: {
                    'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                },
            });

            logDiagnostic({ teste: 4, userId, status: response.status });

            if (response.status === 200) {
                setExistingSubscription(newSubscription);
                setNotificationsEnabled(true);
            }
        } catch (error) {
            alert('Ocorreu um erro ao tentar se inscrever.');
        } finally {
            setIsSubscriptionLoading(false);
        }
    }

    const checkSubscription = async () => {
        const newUserId = await fetchUserId();
        setNotificationsEnabled(Notification.permission === 'granted');

        try {
            const registration = await getRegistrationWithPushManager();

            logDiagnostic({ teste: 5, userId: newUserId, registration });

            const existingSubscriptionChecked = await waitForSubscription(registration);

            logDiagnostic({ teste: 6, userId: newUserId, existingSubscriptionChecked });

            setExistingSubscription(existingSubscriptionChecked);
        } catch (error) {
            logDiagnostic({ teste: 7, userId: newUserId, error });

            alert('Erro ao checar inscrição:', error);
        }
    };

    useEffect(() => {
        logDiagnostic({
            context: 'teste login , isSubscriptionLoading',
            isSubscriptionLoading,
        });

        if (!isSubscriptionLoading) {
            checkSubscription().finally(async () => {
                setIsLoading(false);

                logDiagnostic({
                    teste: 8,
                    userId,
                    isLoading: false,
                    iosblock: !notificationsEnabled || !existingSubscription,
                    notificationsEnabled,
                    existingSubscription,
                });
            });
        }
    }, [isSubscriptionLoading]);

    if (!isLoading && !userId) {
        logDiagnostic({
            context: 'teste login , !isLoading && !userId',
            isLoading: isLoading,
            userId: userId,
        });

        return <EmailLoginPage setIsSubscriptionLoading={setIsSubscriptionLoading} />;
    }

    if (isLoading || !userId) {
        return (
            <ContentLoadingWrapper>
                <LoaderWrapper bgColor='#171717' />
            </ContentLoadingWrapper>
        );
    }

    if (!notificationsEnabled || !existingSubscription) {
        return (
            <Content>
                <LogoImage src='https://avel-hub-images.s3.us-east-2.amazonaws.com/doodles/current.png' alt='logo' />
                <AvelBell src='https://avel-hub-images.s3.us-east-2.amazonaws.com/misc/avel_bell.png' />
                <Title>Ative as notificações do App</Title>
                <MainText>Notificações são necessárias para continuar</MainText>
                {!isSubscriptionLoading ? (
                    <AcceptButtonBorder>
                        <AcceptButton onClick={subscribeToPush}>
                            Ativar <NotificationsActive size='24px' color='#FFFFFF' />
                        </AcceptButton>
                    </AcceptButtonBorder>
                ) : (
                    <LoaderWrapper bgColor='#171717' />
                )}
            </Content>
        );
    }

    return (
        <Suspense>
            <Outlet />
        </Suspense>
    );
}
