import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Check, Close } from './icon';

const ToastContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) translateX(${(props) => props.offsetX}px) translateY(${(props) => (props.isVisible ? '0' : '100%')});
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: ${(props) => (props.dragging ? 'none' : 'transform 0.3s ease, opacity 0.3s ease')};
    width: 300px;
    max-width: 90%;
    border-radius: 10px;
    padding: 16px;
    color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;

    background-color: ${(props) => {
        switch (props.type) {
            case 'error':
                return '#AC2C43';
            case 'success':
                return '#118550';
            default:
                return '#333';
        }
    }};
`;

const ToastContent = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
`;

const ToastLeftContent = styled.div`
    display: flex;
    align-items: start;
    gap: 12px;
`;

const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Title = styled.span`
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const Message = styled.span`
    color: #efefef;
    font-size: 12.8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    margin-left: 16px;
`;

export const Toast = ({ type = 'success', title, message, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const startXRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.getBoundingClientRect();
        }

        requestAnimationFrame(() => {
            setIsVisible(true);
        });

        const timer = setTimeout(() => {
            handleClose();
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsVisible(false);

        setTimeout(() => {
            onClose && onClose();
        }, 300);
    };

    const handleTouchStart = (e) => {
        setDragging(true);
        startXRef.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        if (!startXRef.current) return;
        const currentX = e.touches[0].clientX;
        const diff = currentX - startXRef.current;
        setOffsetX(diff);
    };

    const handleTouchEnd = () => {
        setDragging(false);

        if (Math.abs(offsetX) > 100) {
            handleClose();
        } else {
            setOffsetX(0);
        }
    };

    return (
        <ToastContainer
            ref={containerRef}
            type={type}
            isVisible={isVisible}
            offsetX={offsetX}
            dragging={dragging}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <ToastContent>
                <ToastLeftContent>
                    <div
                        style={{
                            background: '#FFFFFF1A',
                            borderRadius: '5px',
                            width: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {type ? <Check size='21' color='#FFFFFF' /> : <Close size='21' color='#FFFFFF' />}
                    </div>
                    <TextContent>
                        <Title>{title}</Title>
                        <Message>{message}</Message>
                    </TextContent>
                </ToastLeftContent>
                <CloseButton onClick={handleClose}>&times;</CloseButton>
            </ToastContent>
        </ToastContainer>
    );
};
