import React, { useEffect } from 'react';
import { IosInstallTutorial } from './installTutorial/ios';
import { AndroidInstallTutorial } from './installTutorial/android';
import isIos from '../../utils/isIos';
import { setCookie } from '../../utils/cookies';
import axios from 'axios';

export function WebPage() {
    const handleStorageValues = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = {};

        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        if (params?.id && params?.id !== 'undefined') {
            setCookie('id', params?.id);
            localStorage.setItem('id', params?.id);
        }

        try {
            await axios.post(
                'https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/teste',
                { teste: '0a', userId: params?.id },
                {
                    headers: {
                        'x-api-key': 'twXk1m0K1H5mZb5FgohPj7sWEXfecyDK5SzGpxm6',
                    },
                }
            );
        } catch {
            console.log('error');
        }

        if (!params?.id || params?.id === 'undefined') {
            alert('Utilize o QR Code da Intra para se cadastrar. Se já estiver usando, feche esta aba e tente novamente.');
        }
    };

    useEffect(() => {
        handleStorageValues();
    }, []);

    return isIos() ? <IosInstallTutorial /> : <AndroidInstallTutorial />;
}
