import styled from 'styled-components';
import { Close } from './icon';
import { useState } from 'react';

const UserImage = styled.img`
    object-fit: cover;
    width: 52px;
    height: 52px;
    border-radius: 100%;
`;

const ModalWrapper = styled.div`
    z-index: 10;
    background: #252525;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: calc(100% - 40px);
    max-width: 380px;
    padding: 16px;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ModalTitle = styled.span`
    color: #f4f4f4;
    font-size: 14px;
    font-weight: bold;
`;

const CloseButton = styled.span`
    cursor: pointer;
`;

const ProfileRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #414141;
`;

const ProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const UserName = styled.span`
    color: #efefef;
    font-size: 14px;
`;

const UserEmail = styled.span`
    color: #a2a1a6;
    font-size: 12px;
`;

const GemImage = styled.img`
    width: 18px;
    height: 18px;
`;

export function ProfileModal({ cancel, user }) {
    const [defaultImg, setDefaultImg] = useState();

    return (
        <ModalWrapper>
            <ModalHeader>
                <ModalTitle>Logado como</ModalTitle>

                <CloseButton onClick={cancel}>
                    <Close size='19px' color='#EFEFEF' />
                </CloseButton>
            </ModalHeader>

            <ProfileRow>
                <UserImage
                    src={defaultImg || `https://avel-hub-images.s3.us-east-2.amazonaws.com/users/${user?.userId}/profile.jpg`}
                    onError={() => setDefaultImg('https://avel-hub-images.s3.us-east-2.amazonaws.com/default/user.png')}
                    alt='Foto do usuário'
                />

                <ProfileInfo>
                    <UserName>{user?.name_display || '-'}</UserName>
                    <UserEmail>{user?.email || '-'}</UserEmail>
                </ProfileInfo>
            </ProfileRow>

            <div style={{ display: 'flex', flexDirection: 'column', color: '#FFFFFF', fontWeight: '600', gap: '16px', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '50%' }}>
                        <span style={{ fontSize: '10.24px', color: '#FFFFFF99' }}>RAIA</span>
                        <span style={{ fontSize: '12.8px' }}>{user?.lane || '-'}° Raia</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '50%' }}>
                        <span style={{ fontSize: '10.24px', color: '#FFFFFF99' }}>POSIÇÃO</span>
                        <span style={{ fontSize: '12.8px' }}>{user?.position || '-'}° Lugar</span>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
                    <span style={{ fontSize: '10.24px', color: '#FFFFFF99' }}>LIMITE NA SEMANA</span>

                    <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', width: '100%' }}>
                        {user?.leadsInfo && user?.leadsInfo?.length
                            ? user?.leadsInfo?.map((limit) => {
                                  return (
                                      <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                          <GemImage
                                              src={`https://avel-hub-images.s3.us-east-2.amazonaws.com/score/gem_${limit?.name}.svg`}
                                              alt='gem'
                                          />
                                          <span style={{ fontSize: '12.8px', color: '#FFFFFF99' }}>
                                              {limit?.recieved} / {limit?.limit}
                                          </span>
                                      </div>
                                  );
                              })
                            : '-'}
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
}
