import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Download } from '../../../../components/icon';

const Content = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(23, 23, 23, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: 10vh 5% 0 5%;
    align-items: center;
`;

const LogoImage = styled.img`
    object-fit: contain;
    width: 87px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 24px;
`;

const Title = styled.h1`
    color: #f8f8f8;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 24px;
`;

const MainText = styled.p`
    color: #38aa76;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 64px;
`;

const Text = styled.p`
    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 32px;
`;

const AcceptButtonBorder = styled.div`
    border: 1px solid #2891614a;
    padding: 2px;
    border-radius: 10px;
`;

const AcceptButton = styled.button`
    background: #118550;
    color: #ffffff;
    border: 1px solid #289161;
    border-radius: 8px;
    width: 155px;
    height: 38px;
    font-size: 12.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

export function AndroidInstallTutorial() {
    const [installPromptEvent, setInstallPromptEvent] = useState(null);

    const handleInstallClick = async () => {
        if (!installPromptEvent) return;

        installPromptEvent.prompt();

        await installPromptEvent.userChoice;

        setInstallPromptEvent(null);
    };

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setInstallPromptEvent(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    return (
        <Content>
            <LogoImage src='https://avel-hub-images.s3.us-east-2.amazonaws.com/doodles/current.png' alt='logo' />

            <Title>Adicione o App à sua tela de início</Title>

            <MainText>O App notifica você sobre novas reuniões e permite aceitá-las ou recusá-las diretamente no seu celular.</MainText>

            <Text>Toque no botão abaixo para instalar o app no seu Android: </Text>

            <AcceptButtonBorder>
                <AcceptButton onClick={handleInstallClick}>
                    Instalar App <Download size='24px' color='#FFFFFF' />
                </AcceptButton>
            </AcceptButtonBorder>
        </Content>
    );
}
