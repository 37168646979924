import axios from 'axios';
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ArrowForward } from '../../components/icon';
import { Toast } from '../../components/Toast';
import { getCookie, setCookie } from '../../utils/cookies';
import { useNavigate } from 'react-router-dom';
import { logDiagnostic } from '../../utils/logDiagnostic';

const rotate = keyframes`
    to {
      transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;

    &:before,
    &:after {
        content: '';
        position: absolute;
        border-radius: inherit;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #1dc077 0%, transparent 100%);
        animation: ${rotate} 0.5s infinite linear;
    }

    &:after {
        width: 85%;
        height: 85%;
        background-color: ${(props) => props.bgColor};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const Content = styled.div`
    background: radial-gradient(145.77% 67.36% at 50% 4.15%, rgba(56, 183, 119, 0.15) 0%, rgba(56, 183, 119, 0) 100%), var(--background-01, #171717);
    display: flex;
    flex-direction: column;
    padding: 10vh 5% 0 5%;
    align-items: center;
`;

const LogoImage = styled.img`
    object-fit: contain;
    width: 87px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 24px;
`;

const Title = styled.h1`
    color: #f8f8f8;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 24px;
`;

const Text = styled.p`
    color: #c0c0c0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 32px;
`;

const AcceptButtonBorder = styled.div`
    border: 1px solid #2891614a;
    padding: 2px;
    border-radius: 10px;
`;

const AcceptButton = styled.button`
    background: #118550;
    color: #ffffff;
    border: 1px solid #289161;
    border-radius: 8px;
    width: 155px;
    height: 38px;
    font-size: 12.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 294px;
`;

const CustomBorder = styled.div`
    width: 342px;
    height: 2px;
    position: absolute;
    top: -1.5px;
    transition: width 0.5s;
    background-image: linear-gradient(90deg, rgba(116, 148, 179, 0) 0%, #1dc077 49.5%, rgba(116, 148, 179, 0) 100%);
`;

const InputsContainer = styled.div`
    position: relative;
    border-radius: 8px;
    border: 2px solid #1dc0771a;
    background: radial-gradient(62.32% 37.76% at 50% 6.86%, rgba(29, 192, 119, 0.1) 0%, rgba(29, 192, 119, 0) 100%) no-repeat,
        var(--background-02, #1e1e1e);
    box-shadow: 0px 4px 20.3px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    max-width: 342px;
`;

const Label = styled.span`
    color: #fff;
    font-size: 12.8px;
    font-weight: semi-bold;
`;

const Input = styled.input`
    background: #1e1e1e;
    font-size: 12.8px;
    color: #f0f0f0;
    padding: 11px 12px;
    border: 1px solid #ffffff26;
    border-radius: 6px;
    outline: none;
`;

export function EmailLoginPage({ setIsSubscriptionLoading }) {
    const [email, setEmail] = useState();
    const [isVerificationLoading, setIsVerificationLoading] = useState(false);
    const [toastData, setToastData] = useState(null);

    const navigate = useNavigate();

    const verifyEmail = async () => {
        setIsSubscriptionLoading(true);
        setIsVerificationLoading(true);

        try {
            await logDiagnostic({
                context: 'Entrei no teste login',
            });

            const response = await axios.post('https://ctbzpz0ih4.execute-api.us-east-2.amazonaws.com/app/get_id', {
                email,
            });

            await logDiagnostic({
                context: 'teste login',
                response: response.data,
            });

            setCookie('id', response.data);
            localStorage.setItem('id', response.data);

            const cookietest = getCookie('id');
            const localtest = localStorage.getItem('id');

            await logDiagnostic({
                context: 'teste login , Recarregar',
                cookietest,
                localtest,
            });

            setIsSubscriptionLoading(false);
            navigate('/app');
        } catch {
            await logDiagnostic({
                context: 'teste login , deu erro',
            });

            setToastData({
                title: 'E-mail inválido',
                message: 'Utilize o mesmo e-mail do seu login da Intranet',
                type: 'error',
            });
        }

        await logDiagnostic({
            context: 'teste login , this is the end',
        });

        setIsSubscriptionLoading(false);
        setIsVerificationLoading(false);
    };

    return (
        <>
            <Content>
                <LogoImage src='https://avel-hub-images.s3.us-east-2.amazonaws.com/doodles/current.png' alt='logo' />

                <Title>Bem vindo</Title>

                <Text>Insira seu e-mail da Intranet</Text>

                <InputsContainer>
                    <CustomBorder></CustomBorder>
                    <InputContainer>
                        <Label>E-mail</Label>

                        <Input onChange={(e) => setEmail(e.target.value)} placeholder='exemplo@email.com' />
                    </InputContainer>

                    {!isVerificationLoading ? (
                        <AcceptButtonBorder>
                            <AcceptButton onClick={verifyEmail}>
                                Continuar <ArrowForward size='20px' color='#FFFFFF' />
                            </AcceptButton>
                        </AcceptButtonBorder>
                    ) : (
                        <LoaderWrapper bgColor='#1e1e1e' />
                    )}
                </InputsContainer>
            </Content>

            {toastData && <Toast type={toastData.type} title={toastData.title} message={toastData.message} onClose={() => setToastData(null)} />}
        </>
    );
}
