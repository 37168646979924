export const Close = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z' />
        </svg>
    );
};

export const Download = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z' />
        </svg>
    );
};

export const IOSShare = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z' />
        </svg>
    );
};

export const NotificationsActive = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M80-560q0-100 44.5-183.5T244-882l47 64q-60 44-95.5 111T160-560H80Zm720 0q0-80-35.5-147T669-818l47-64q75 55 119.5 138.5T880-560h-80ZM160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z' />
        </svg>
    );
};

export const ArrowForward = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z' />
        </svg>
    );
};

export const Check = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z' />
        </svg>
    );
};

export const Help = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z' />
        </svg>
    );
};

export const Meet = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 0 21 18' width={size} fill={color} {...props}>
            <path d='M12.1946 1.00238H6.12571C5.54207 0.980769 5.47107 1.11048 5.36913 1.21856C4.17293 2.48675 1.71578 5.09238 1.45637 5.36909C1.19696 5.64579 1.18975 5.90231 1.21858 5.99599V16.0048C1.21858 16.8349 1.8671 17.0136 2.19136 16.9992H15.4212C16.061 16.9992 16.394 16.3363 16.4804 16.0048V12.6974C17.3883 13.4035 19.2907 14.8764 19.6366 15.1185C19.9824 15.3606 20.0833 14.9456 20.0905 14.7078C20.0833 11.1337 20.0732 3.86884 20.0905 3.40191C20.1078 2.93497 19.7951 2.99118 19.6366 3.07765L18.0585 4.22337L16.4804 5.36909V1.95355C16.4804 1.17532 15.6734 0.995177 15.2699 1.00238H12.207M8.97327 12.5236H5.49326V6.21138C5.49326 5.5888 5.94002 5.47639 6.1634 5.49801H11.0705C11.6585 5.49801 11.7911 5.97359 11.7839 6.21138V11.8103C11.7839 12.3291 11.3083 12.502 11.0705 12.5236H8.98626' />
        </svg>
    );
};

export const ContentCopy = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 -960 960 960' width={size} fill={color} {...props}>
            <path d='M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z' />
        </svg>
    );
};

export const Pipedrive = ({ size, color, ...props }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height={size} viewBox='0 0 306 386' width={size} fill={color} {...props}>
            <path d='M182.949 0C140.009 0 115.11 19.4804 103.202 32.4673C101.759 20.9234 94.181 6.1327 64.5916 6.1327H0V73.2318H26.3418C30.672 73.2318 32.1154 74.6747 32.1154 79.0037V386H108.615V271.282V262.624C120.523 273.447 143.256 288.598 178.98 288.598C253.675 288.598 305.998 229.435 305.998 144.299C306.359 58.0804 256.562 0 182.949 0ZM167.433 221.86C126.296 221.86 107.532 182.538 107.532 145.742C107.532 88.0224 138.926 67.4598 168.515 67.4598C204.6 67.4598 229.138 98.4841 229.138 145.021C228.777 198.411 197.744 221.86 167.433 221.86Z' />
        </svg>
    );
};
