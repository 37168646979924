import React, { useState, useEffect } from 'react';

export function CountdownTimer({ timeLimit, onExpire }) {
    const calculateTimeLeft = () => {
        const difference = new Date(timeLimit) - new Date();
        if (difference > 0) {
            return {
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return { minutes: 0, seconds: 0 };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);

            if (newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
                if (onExpire) onExpire();
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLimit, onExpire]);

    const formatSeconds = timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds;

    return (
        <span>
            {timeLeft.minutes}:{formatSeconds}
        </span>
    );
}
