import Cookies from 'js-cookie';

export function setCookie(key, value) {
    Cookies.set(key, value);
}

export function getCookie(key) {
    const sessionidCookie = Cookies.get(key);

    return sessionidCookie;
}
